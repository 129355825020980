/**
 * Note:
 * This file should be synced with the corresponding layout in Contenful CMS.
 */
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import {
  params,
  string,
  shape,
  extend,
} from '@thd-nucleus/data-sources';
import { Hero, HeroPersonalized } from '@thd-olt-component-react/hero';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { Endcap } from '@thd-olt-component-react/endcap';
import { MedioInline, PiccolaPencil, MedioComposableBanner, PiccolaComposableBanner } from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { BrowseSearchMetadata } from '@thd-olt-component-react/metadata';
import { RelatedSearch } from '@thd-olt-component-react/related-search';
import { Resource } from '@thd-olt-component-react/resource';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { SponsoredBanner } from '@thd-olt-component-react/sponsored-content';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { PromoVisualNavigation, VisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import { LandingPageRedirector, LandingPageSearchRedirector } from '@thd-nucleus/app-render';
import {
  DynamicComponentSelector, DynamicSlot,
  HeroComponentSelector, HeroSlot,
  ResourceComponentSelector, ResourceSlot,
  SpotlightComponentSelector, SpotlightSlot
} from './personalization';
import {
  breadcrumbFragment,
  educationalLayoutFragment,
  navigationalLayoutFragment,
  inspirationalLayoutFragment,
  universalLayoutFragment
} from '../templates/dataModel';

export const dataModel = extend(
  {
    layouts: params({
      slug: string().isRequired(),
      customerType: string()
    }).shape({
      ...breadcrumbFragment,
      slug: string(),
      type: string(),
      seo: shape({
        canonicalURL: string(),
        description: string(),
        facebookAdmins: string(),
        siteName: string(),
        title: string(),
        type: string(),
        linkData: string(),
        scriptData: string(),
        no_index: string(),
        no_follow: string()
      }),
      content: shape({
        __typename: string(),
        EducationalLayout: educationalLayoutFragment,
        NavigationalLayout: navigationalLayoutFragment,
        InspirationalLayout: inspirationalLayoutFragment,
        UniversalLayout: universalLayoutFragment
      }),
    }),
  },
  // you need this here too because the query is asked for at the root level
  Breadcrumbs,
  BrowseSearchMetadata,
  CalloutCard,
  CapabilityCard,
  ContentAccordion,
  ContentGalleryCard,
  DynamicComponentSelector,
  DynamicSlot,
  EmtLinks,
  Endcap,
  Hero,
  HeroComponentSelector,
  HeroPersonalized,
  HeroSlot,
  LandingPageRedirector,
  LandingPageSearchRedirector,
  MedioInline,
  MedioComposableBanner,
  NewspaperModule,
  PiccolaPencil,
  PiccolaComposableBanner,
  ProductShelf,
  PromoVisualNavigation,
  RelatedSearch,
  Resource,
  ResourceComponentSelector,
  ResourceSlot,
  SideNavigation,
  SpecialBuyBanner,
  SponsoredBanner,
  Spotlight,
  SpotlightContainer,
  SpotlightComponentSelector,
  SpotlightSlot,
  VisualNavigation,
);
